<template>
  <div>
    <v-container fluid>
      <!-- <ClaimForm /> -->

      <v-tabs>
        <v-tab
          @click="
            incoming = true;
            pending = false;
            query = false;
            approved = false;
          "
          >New</v-tab
        >
        <v-tab
          @click="
            pending = true;
            incoming = false;
            query = false;
            approved = false;
          "
          >Pending</v-tab
        >
        <v-tab
          @click="
            query = true;
            pending = false;
            incoming = false;
            approved = false;
          "
          >Query</v-tab
        >
        <v-tab
          @click="
            approved = true;
            pending = false;
            query = false;
            incoming = false;
          "
          >Approved</v-tab
        >
        <!-- <v-tab
          @click="
            summary = true;
            approved = false;
            query = false;
            incoming = false;
          "
          >Summary</v-tab
        > -->
      </v-tabs>

      <incTab v-if="incoming" />
      <pendTab v-if="pending" />
      <cancTab v-if="query" />
      <approvedTab v-if="approved" />
      <!-- <summaryTab v-if="summary" /> -->
    </v-container>
  </div>
</template>

<script>
import pendTab from "./pendingList";
import incTab from "./incomingList.vue";
import cancTab from "./queryList.vue";
import approvedTab from "./approvedList.vue";
// import summaryTab from "./summaryList.vue";
// import ClaimForm from "../../components/pdf/claimForm.vue";

export default {
  name: "claimsList",
  components: {
    pendTab,
    incTab,
    cancTab,
    approvedTab,
    // summaryTab,
    // ClaimForm,
  },
  data() {
    return {
      pending: false,
      claimform: false,
      incoming: true,
      query: false,
      approved: false,
      // summary: false,
    };
  },
};
</script>
